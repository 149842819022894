<template>
  <div class="home-container">
    <el-row :gutter="10">
      <!-- 左边 -->
      <el-col :span="6">
        <!-- 日期 -->
        <div class="left-date">{{ leftDate }}</div>
        <!-- 人数统计 -->
        <!-- <div class="left-num">
          <div>
            <span>累计借阅次数</span>
            <span>44688</span>
          </div>
          <div>
            <span>累计借阅次数</span>
            <span>44688</span>
          </div>
          <div>
            <span>累计借阅次数</span>
            <span>44688</span>
          </div>
          <div>
            <span>累计借阅次数</span>
            <span>44688</span>
          </div>
        </div> -->
        <!-- 今日书刊借阅 -->
        <div class="day-book-borrow book-height position-dashed">
          <span class="left-span">今日书刊借阅</span>
          <div class="left-top-border"></div>
          <div class="left-bottom-border"></div>
          <div class="right-top-border"></div>
          <div class="right-bottom-border"></div>
          <div class="day-borrow" @mouseover="moveStop" @mouseleave="moveStart">
            <div class="book-borrow">
              <p class="borrow-line">
                <span>陈伟</span>
                <span>借阅图书《我不是天后》</span>
                <span>08:35:25</span>
              </p>
              <p class="borrow-line">
                <span>陈伟</span>
                <span>借阅图书《我不是天后》</span>
                <span>08:35:25</span>
              </p>
            </div>
          </div>
        </div>
        <!-- 热门图书排行 -->
        <div class="book-height position-dashed">
          <span class="left-span">热门图书排行</span>
          <div class="left-top-border"></div>
          <div class="left-bottom-border"></div>
          <div class="right-top-border"></div>
          <div class="right-bottom-border"></div>
          <!-- 图表 -->
          <div id="hot-rank" class="chart"></div>
        </div>
        <div class="book-height position-dashed">
          <span class="left-span">月借书量统计</span>
          <div class="left-top-border"></div>
          <div class="left-bottom-border"></div>
          <div class="right-top-border"></div>
          <div class="right-bottom-border"></div>
          <!-- 图表 -->
          <div id="month-borrow" class="chart"></div>
        </div>
      </el-col>
      <!-- 中间 -->
      <el-col :span="10">
        <!-- 标题 -->
        <div class="center-title">流通数据统计平台</div>
        <!-- 通告 -->
        <div class="msg-title"><span>通知公告:</span> <span>测试</span></div>

        <div class="day-circ book-height">
          <div class="position-dashed">
            <span class="left-span">今日图书流通</span>
            <div class="left-top-border"></div>
            <div class="left-bottom-border"></div>
            <div class="right-top-border"></div>
            <div class="right-bottom-border"></div>
            <!-- 图表 -->
            <div id="day-circulate" class="chart"></div>
          </div>
          <div class="position-dashed">
            <span class="left-span">图书状态</span>
            <div class="left-top-border"></div>
            <div class="left-bottom-border"></div>
            <div class="right-top-border"></div>
            <div class="right-bottom-border"></div>
            <!-- 图表 -->
            <div id="book-status" class="chart"></div>
          </div>
        </div>

        <div class="book-class position-dashed">
          <span class="left-span">图书分类数量统计</span>
          <div class="left-top-border"></div>
          <div class="left-bottom-border"></div>
          <div class="right-top-border"></div>
          <div class="right-bottom-border"></div>
          <!-- 图表 -->
          <div id="book-class" class="chart"></div>
        </div>

        <div class="book-trend position-dashed">
          <span class="left-span">图书借阅趋势</span>
          <div class="left-top-border"></div>
          <div class="left-bottom-border"></div>
          <div class="right-top-border"></div>
          <div class="right-bottom-border"></div>
          <!-- 图表 -->
          <div id="book-trend" class="chart"></div>
        </div>
      </el-col>
      <!-- 右边 -->
      <el-col :span="8">
        <!-- 时间 -->
        <div class="right-date">{{ rightWeek }}{{ rightTime }}</div>

        <div class="right-height position-dashed">
          <span class="left-span">图书推荐</span>
          <div class="left-top-border"></div>
          <div class="left-bottom-border"></div>
          <div class="right-top-border"></div>
          <div class="right-bottom-border"></div>
          <!-- 图书推荐 -->
          <div class="rota-container">
            <el-carousel
              class="my-carousel"
              :interval="40000"
              type="card"
              height="35vh"
            >
              <el-carousel-item v-for="item in 6" :key="item">
                <img src="../../assets/img/book.jpg" alt="" />
                <span
                  >唐诗三百首唐诗三百首唐诗三百首唐诗三百首唐诗三百首唐诗三百首唐诗三百首唐诗三百首</span
                >
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>

        <div class="right-height position-dashed">
          <span class="left-span">分类借阅偏好(月)</span>
          <div class="left-top-border"></div>
          <div class="left-bottom-border"></div>
          <div class="right-top-border"></div>
          <div class="right-bottom-border"></div>
          <!-- 图表 -->
          <div id="book-pre" class="chart"></div>
        </div>

        <!-- <div class="book-height position-dashed">
          <span class="left-span">安全门报警</span>
          <div class="left-top-border"></div>
          <div class="left-bottom-border"></div>
          <div class="right-top-border"></div>
          <div class="right-bottom-border"></div>
        </div> -->
      </el-col>
    </el-row>
  </div>
</template>
<script>
import * as echarts from "echarts"
export default {
  data() {
    return {
      leftDate: "2021年6月10日",
      rightWeek: "周四",
      rightTime: " 14:33:36",
      moveTime: null,
      hotRankOption: {
        tooltip: {
          show: true,
          backgroundColor: "rgba(0,0,0,0.7)", //背景颜色（此时为默认色）
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
          padding: 10,
          formatter: "{b} : {c}",
        },
        xAxis: {
          type: "category",
          data: ["my就撒开的JFK觉得是看风景啊电视剧",'啊大撒法打发打发士大夫就开始的解放军','啊的说法大幅度士大夫似的发射点','的撒发射点范德萨发到付'],
          axisLabel: {
            //x轴颜色
            color: "#fff",
            formatter : params=>{
                // console.log(this.hotRankOption.series[0].data)
                if(this.hotRankOption.series[0].data.length<=3)return params
                   var newParamsName = "";// 最终拼接成的字符串
                            var paramsNameNumber = params.length;// 实际标签的个数
                            var provideNumber = 5;// 每行能显示的字的个数
                            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);// 换行的话，需要显示几行，向上取整
                            /**
                             * 判断标签的个数是否大于规定的个数， 如果大于，则进行换行处理 如果不大于，即等于或小于，就返回原标签
                             */
                            // 条件等同于rowNumber>1
                            if (paramsNameNumber > provideNumber) {
                                /** 循环每一行,p表示行 */
                                for (var p = 0; p < rowNumber; p++) {
                                    var tempStr = "";// 表示每一次截取的字符串
                                    var start = p * provideNumber;// 开始截取的位置
                                    var end = start + provideNumber;// 结束截取的位置
                                    // 此处特殊处理最后一行的索引值
                                    if (p == rowNumber - 1) {
                                        // 最后一次不换行
                                        tempStr = params.substring(start, paramsNameNumber);
                                    } else {
                                        // 每一次拼接字符串并换行
                                        tempStr = params.substring(start, end) + "\n";
                                    }
                                    newParamsName += tempStr;// 最终拼成的字符串
                                }

                            } else {
                                // 将旧标签的值赋给新标签
                                newParamsName = params;
                            }
                            //将最终的字符串返回
                            return newParamsName
                }
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            //y轴颜色
            color: "#fff",
          },
        },
        series: [
          {
            data: [
              120,
              {
                value: 200,
                itemStyle: {
                  color: "#a90000",
                },
              },
              150,
              80
              // 70,
              // 110,
              // 130,
            ],
            type: "bar",
          },
        ],
      },
      monthBorrowOption: {
        tooltip: {
          trigger: "axis",
          show: true,
          backgroundColor: "rgba(0,0,0,0.7)", //背景颜色（此时为默认色）
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
          padding: 10,
          formatter: "{b} : {c}",
        },
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          axisLabel: {
            color: "#fff",
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#fff",
          },
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: "line",
            itemStyle: {
              normal: {
                color: "#fff", //折点颜色
                lineStyle: {
                  color: "#37A2DA", //折线的颜色
                },
                label: {
                  //折点数据显示
                  show: true,
                  color: "#E0C835",
                  fontSize: 12,
                },
              },
            },
          },
        ],
      },
      dayCirculateOption: {
        tooltip: {
          trigger: "item",
          show: true,
          backgroundColor: "rgba(0,0,0,0.7)", //背景颜色（此时为默认色）
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
          padding: 10,
          formatter: "{b} : {c}({d}%)",
        },
        legend: {
          // top: "5%",
          left: "right",
          align: "left",
          orient: "vertical",
          textStyle: {
            color: "#fff",
          },
        },
        series: [
          {
            // name: "访问来源",
            type: "pie",
            radius: ["40%", "60%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "outside", //引导线设置
              color: "inherit",
            },
            data: [
              { value: 1048, name: "外借" },
              { value: 735, name: "归还" },
              { value: 580, name: "预借" },
            ],
          },
        ],
      },
      bookStatusOption: {
        tooltip: {
          trigger: "item",
          show: true,
          backgroundColor: "rgba(0,0,0,0.7)", //背景颜色（此时为默认色）
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
          padding: 10,
          formatter: "{b} : {c}({d}%)",
        },
        legend: {
          // top: "5%",
          left: "right",
          align: "left",
          orient: "vertical",
          textStyle: {
            color: "#fff",
          },
        },
        series: [
          {
            // name: "访问来源",
            type: "pie",
            radius: ["40%", "60%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "outside",
              color: "inherit",
            },
            data: [
              { value: 1048, name: "在库" },
              { value: 735, name: "借阅" },
              { value: 580, name: "预借" },
              { value: 484, name: "其他" },
            ],
          },
        ],
      },
      bookClassOption: {
        tooltip: {
          trigger: "item",
          show: true,
          backgroundColor: "rgba(0,0,0,0.7)", //背景颜色（此时为默认色）
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
          padding: 10,
          formatter: "{b} : {c}({d}%)",
        },
        legend: {
          // top: "5%",
          type: "scroll",
          left: "right",
          align: "left",
          orient: "vertical",
          textStyle: {
            color: "#fff",
          },
        },
        series: [
          {
            // name: "访问来源",
            type: "pie",
            radius: ["40%", "60%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "outside",
              color: "inherit",
            },
            data: [
              { value: 1048, name: "搜索引擎" },
              { value: 735, name: "直接访问" },
              { value: 580, name: "邮件营销" },
              { value: 484, name: "联盟广告" },
              { value: 300, name: "视频广告" },
              { value: 1048, name: "a" },
              { value: 735, name: "b" },
              { value: 580, name: "c" },
              { value: 484, name: "d" },
              { value: 300, name: "e" },
              { value: 1048, name: "f" },
              { value: 735, name: "g" },
              { value: 580, name: "h" },
              { value: 484, name: "i" },
              { value: 300, name: "j" },
              { value: 1048, name: "gg" },
              { value: 735, name: "ss" },
              { value: 580, name: "邮件营ww销" },
              { value: 484, name: "aa" },
              { value: 300, name: "ss" },
              { value: 1048, name: "ff" },
              { value: 735, name: "se" },
              { value: 580, name: "ww" },
              { value: 484, name: "kk" },
              { value: 300, name: "sss" },
            ],
          },
        ],
      },
      bookTrendOption: {
        tooltip: {
          trigger: "axis",
          show: true,
          backgroundColor: "rgba(0,0,0,0.7)", //背景颜色（此时为默认色）
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
          padding: 10,
          formatter: "{b} : {c}",
        },
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          axisLabel: {
            color: "#fff",
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#fff",
          },
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: "line",
            smooth: true,
            itemStyle: {
              normal: {
                color: "#fff",
                lineStyle: {
                  color: "#37A2DA",
                },
                label: {
                  show: true,
                  color: "#E0C835",
                  fontSize: 12,
                },
              },
            },
          },
        ],
      },
      bookPreOption: {
        tooltip: {
          show: true,
          backgroundColor: "rgba(0,0,0,0.7)", //背景颜色（此时为默认色）
          textStyle: {
            color: "#fff",
            fontSize: 12,
          },
          padding: 10,
          // formatter: function (params) {
          //   console.log('参数',params)
          // }
        },
        radar: {
          // shape: 'circle',
          indicator: [
            { name: "文化艺术", max: 6500, color: "#fff" },
            { name: "历史地理", max: 16000, color: "#fff" },
            { name: "其他", max: 30000, color: "#fff" },
            { name: "自然科学", max: 38000, color: "#fff" },
            { name: "政治军事经济", max: 52000, color: "#fff" },
          ],
        },
        series: [
          {
            type: "radar",
            data: [
              {
                value: [4200, 3000, 20000, 35000, 50000],
                name: "分类借阅偏好",
              },
            ],
            itemStyle: {
              normal: {
                color: "#fff", //折点颜色
                lineStyle: {
                  color: "#D61C28", //折线的颜色
                },
                label: {
                  //折点数据显示
                  show: true,
                  color: "#fff",
                  fontSize: 12,
                },
              },
            },
            areaStyle: {
              //覆盖区域颜色
              color: "#9BBDD3",
            },
          },
        ],
      },
    };
  },
  created() {
    this.getDate();
  },
  mounted() {
    this.initEcharts();
    this.move();
  },
  methods: {
    //获取日期时间
    getDate() {
      var date = new Date();
      this.leftDate =
        date.getFullYear() +
        "年" +
        (date.getMonth() + 1) +
        "月" +
        date.getDate() +
        "日";
      switch (date.getDay) {
        case 0:
          this.rightWeek = "周日";
          break;
        case 1:
          this.rightWeek = "周一";
          break;
        case 2:
          this.rightWeek = "周二";
          break;
        case 3:
          this.rightWeek = "周三";
          break;
        case 4:
          this.rightWeek = "周四";
          break;
        case 5:
          this.rightWeek = "周五";
          break;
        case 6:
          this.rightWeek = "周六";
          break;
      }
      var _this = this;
      //动态时间
      setInterval(function () {
        date = new Date();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();
        _this.rightTime =
          (hours < 10 ? "0" + hours : hours) +
          ":" +
          (minutes < 10 ? "0" + minutes : minutes) +
          ":" +
          (seconds < 10 ? "0" + seconds : seconds);
      }, 1000);
    },
    //元素移动
    move() {
      this.moveTime = setInterval(function () {
        var dayBorrow = document.getElementsByClassName("day-borrow")[0];
        var bookBorrow = document.getElementsByClassName("book-borrow")[0];
        //获取元素的高度
        if (bookBorrow.style.marginTop == "")
          bookBorrow.style.marginTop = 0 + "px";
        bookBorrow.style.marginTop =
          parseInt(bookBorrow.style.marginTop) - 1 + "px";
        var mt = parseInt(bookBorrow.style.marginTop);
        var bookHeight = bookBorrow.offsetHeight;
        if (mt == -bookHeight) {
          bookBorrow.style.marginTop = dayBorrow.offsetHeight + "px";
        }
      }, 100);
    },
    moveStop() {
      clearInterval(this.moveTime);
    },
    moveStart() {
      this.move();
    },
    //初始化图表
    initEcharts() {
      // 热门图书排行
      var hotRankChart = echarts.init(document.getElementById("hot-rank"));
      hotRankChart.setOption(this.hotRankOption);
      // 月借量统计
      var monthBorrowChart = echarts.init(
        document.getElementById("month-borrow")
      );
      monthBorrowChart.setOption(this.monthBorrowOption);
      //今日图书流通
      var dayCirculateChart = echarts.init(
        document.getElementById("day-circulate")
      );
      dayCirculateChart.setOption(this.dayCirculateOption);
      //图书状态
      var bookStatusChart = echarts.init(
        document.getElementById("book-status")
      );
      bookStatusChart.setOption(this.bookStatusOption);
      //图书分类数量统计
      var bookClassChart = echarts.init(document.getElementById("book-class"));
      bookClassChart.setOption(this.bookClassOption);
      //月图书借阅趋势
      var bookTrendChart = echarts.init(document.getElementById("book-trend"));
      bookTrendChart.setOption(this.bookTrendOption);
      //图书推荐
      //分类借阅偏好
      var bookPreChart = echarts.init(document.getElementById("book-pre"));
      bookPreChart.setOption(this.bookPreOption);
      //安全门报警
    },
  },
};
</script>
<style scoped>
.home-container {
  height: 100vh;
  background: #0e3a4f;
  color: #fff;
}
.left-date {
  font-size: 22px;
  height: 6vh;
  background: url("../../assets/img/tj_left.png") no-repeat;
  box-sizing: border-box;
  padding: 10px 0 0 10px;
}
.center-title {
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  height: 6vh;
  line-height: 60px;
  background: url("../../assets/img/tj_center.png") no-repeat;
}
.right-date {
  font-size: 22px;
  height: 6vh;
  background: url("../../assets/img/tj_right.png") no-repeat;
  background-position: top right;
  box-sizing: border-box;
  padding: 10px 10px 0 10px;
  text-align: right;
}
.left-num {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
  margin-bottom: 5px;
}
.left-num div {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 25%;
  height: 10vh;
  margin: 0 10px;
}
.left-num div span:nth-child(1) {
  margin: 30px 0 10px 0;
  font-size: 1vw;
}
.left-num div span:nth-child(2) {
  font-size: 1.3vw;
  font-weight: 700;
}
.left-num div:nth-child(1) {
  border: 1px solid #3cb2ef;
}
.left-num div:nth-child(1) span:nth-child(2) {
  color: #3cb2ef;
}
.left-num div:nth-child(2) {
  border: 1px solid #67e0e3;
}
.left-num div:nth-child(2) span:nth-child(2) {
  color: #67e0e3;
}
.left-num div:nth-child(3) {
  border: 1px solid #ffdb5c;
}
.left-num div:nth-child(3) span:nth-child(2) {
  color: #ffdb5c;
}
.left-num div:nth-child(4) {
  border: 1px solid #d37d0d;
}
.left-num div:nth-child(4) span:nth-child(2) {
  color: #d37d0d;
}
.day-book-borrow {
  position: relative;
  height: 18.5vh;
  padding-top: 35px;
  box-sizing: border-box;
}
.day-borrow {
  height: 100%;
  padding: 0 10px;
  overflow: hidden;
}
.borrow-line {
  display: flex;
  font-size: 16px;
  padding: 8px 10px;
  line-height: 20px;
}
.borrow-line span:nth-child(1) {
  font-size: 18px;
  color: #d37d0d;
  font-weight: 700;
}
.borrow-line span:nth-child(2) {
  flex: 2;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.book-height {
  height: 30vh;
}
.right-height {
  height: 45vh;
}
.book-class {
  height: 34vh;
}
.book-trend {
  height: 20vh;
}
.day-circ {
  display: flex;
  justify-content: space-between;
}
.day-circ > div {
  width: 49%;
}
.msg-title {
  height: 6vh;
  font-size: 1.5vh;
  display: flex;
  align-items: center;
}
.chart {
  width: 95%;
  height: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.book-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.book-item > img {
  width: 9vw;
  height: 19vh;
  margin-bottom: 0.5vh;
}
.book-item > span {
  font-size: 1vw;
}
.el-carousel {
  width: 100%;
  margin: 50px auto;
}
#my-carousel .el-carousel__mask {
  background-color: initial !important;
  opacity: 0 !important;
}
/* 悬浮 */
.position-dashed {
  position: relative;
  border: 1px dashed #879eff;
  margin-bottom: 5px;
}
.left-span {
  position: absolute;
  top: 0;
  left: 0;
  background: #87cefa;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 700;
  padding: 0 5px;
}
.left-top-border,
.left-bottom-border,
.right-top-border,
.right-bottom-border {
  width: 30px;
  height: 30px;
  position: absolute;
}
.left-top-border {
  top: -1px;
  left: -1px;
  border-top: 3px solid #faebd7;
  border-left: 3px solid #faebd7;
}
.left-bottom-border {
  bottom: -1px;
  left: -1px;
  border-bottom: 3px solid #faebd7;
  border-left: 3px solid #faebd7;
}
.right-top-border {
  top: -1px;
  right: -1px;
  border-top: 3px solid #faebd7;
  border-right: 3px solid #faebd7;
}
.right-bottom-border {
  bottom: -1px;
  right: -1px;
  border-bottom: 3px solid #faebd7;
  border-right: 3px solid #faebd7;
}
.rota-container {
  width: 100%;
  padding: 0 30px;
}
.el-carousel__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.el-carousel__item > img {
  width: 9.5vw;
  height: 22vh;
  margin-bottom: 0.5vh;
}
.el-carousel__item > span {
  width: 9.5vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1vw;
}
.my-carousel /deep/ .el-carousel__mask {
  background-color: initial !important;
  opacity: 0 !important;
}
</style>